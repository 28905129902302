body {
	background-color: #ffffff;
}
.btn-custom {
	font-size: 22px;
	font-weight: 700;
	border: 2px solid #000;
	border-radius: 50px;
}
.btn-custom .icon {
	vertical-align: -1px;
}
.form-label {
	font-size: 17px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: 1px;
}
.form-control {
	border-color: #c8c8c8;
	border-width: 0px 0px 1px 0px;
	border-radius: 0px;
	font-family: "Jost", Sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5em;
	color: #7e7e7e;
}
.form-control:focus {
	-webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	outline: 0;
	border-color: #818a91;
}
::selection,
::-moz-selection {
	color: #ffffff;
	background: #0078d7;
}

body.editDataKid #kidImage {
	background-color: #e9e9e9;
}
body.editDataKid #kidImage::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	opacity: 0;
	transition: opacity 0.4s ease;
}
body.editDataKid #kidImage:hover::after {
	opacity: 1;
}
body.editDataKid #kidImage i {
	position: absolute;
	z-index: 1;
	color: transparent;
	transition: color 0.4s ease;
}
body.editDataKid #kidImage:hover i {
	color: #fff;
}

.whatsapp_btn_popup {
	bottom: 30px;
	position: fixed;
	right: 30px;
	z-index: 999999999;
}
.whatsapp_btn_popup .whatsapp_btn_popup_txt {
	background-color: var(--tblr-primary);
	border-radius: 4px;
	color: #43474e;
	font-size: 12px;
	letter-spacing: -.03em;
	line-height: 1.5;
	margin-right: 7px;
	padding: 8px 12px;
	position: absolute;
	right: 100%;
	top: 50%;
	transform: translateY(-50%);
	width: 156px;
	border: solid 1px #AAA;
}
.whatsapp_btn_popup .whatsapp_btn_popup_icon {
	background: #2db742;
	border-radius: 50%;
	box-shadow: 0 6px 8px 2px rgba(0, 0, 0, .14);
	height: 56px;
	width: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.whatsapp_btn_popup .whatsapp_btn_popup_icon i {
	font-size: 36px;
	color: #fff;
	margin-top: -3px;
}

.hover-image-zoom img {
	overflow: hidden;
	transition: transform .5s ease;
}
.hover-image-zoom:hover img {
	transform: scale(1.1);
}

.popover-body {
	background-color: var(--tblr-primary);
	color: #43474e;
	font-weight: 600;
	text-align: center;
}
.popover-arrow::after {
	border-color: var(--tblr-primary);
}